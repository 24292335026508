.edit-zone-form {
    width          : 50vw;
    border         : 4px solid #6A9598;
    border-radius  : 20px;
    margin         : auto;
    padding        : 15px;
    align-content  : center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;

}
