.edit-zone-page {
    width          : 50vw;
    border         : 4px solid #6A9598;
    border-radius  : 20px;
    margin         : auto;
    padding        : 15px;
    align-content  : center;
    justify-content: center;
}


.edit-zone-text {
    text-align    : center;
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
}


.edit-zone-dropdown {
    width : 50%;
    margin: auto;
}