.flavor-selection-list {
  margin: 16px;
}

.flavor-selection-list h1 {
  color        : var(--chaeban-primary);
  margin-bottom: 0;
  margin-top   : 5px;
  text-align   : center;
}

.flavor-selection-list__order-info {
  color         : var(--chaeban-primary);
  font-size     : 0.85em;
  font-weight   : 600;
  margin-top    : 0;
  text-transform: uppercase;
}

.flavor-selection-list__order-info>span+span::before {
  content: ' • ';
}

.flavor-selection-list__back {
  background-color: var(--chaeban-secondary--bg);
  border-radius   : 100px;
  color           : var(--chaeban-secondary);
  display         : flex;
  left            : 16px;
  padding         : 8px;
  position        : absolute;
  top             : 16px;
}

.flavor-change-form__btn-wrapper {
  background-image : linear-gradient(180deg, transparent, #fff 16px, #fff);
  background-repeat: no-repeat;
  display          : flex;
  left             : 0;
  padding          : 16px;
  right            : 0;
}

.flav-list-container {

  width           : 100%;
  justify-content : center;
}

.Next-Page-Button {
  background-color: var(--chaeban-primary);
  border:5px solid var(--chaeban-primary) ;
  text-decoration: none;
  color: white;
  margin: 20px;
  border-radius: 10px;
  padding: 0.5rem;
}

/* .card {
  margin-top: 300px;
} */

@media (max-width: 920px) {
  .flavor-selection-list {
    margin: 8px;
  }

  .flavor-selection-list h1 {
    font-size: 1.5rem;
  }

  .flav-list-container {

    width          : 350px;
    justify-content: center;
    margin         : 10px;
  }
   /* .flavor-change-form__btn {
     width: 60vw;
   } */
}

/* @media (max-width: 370px) {
  .flavor-selection-list {
    margin: 8px;
  }

  .flavor-selection-list h1 {
    font-size: 1.5rem;
  }

  .flav-list-container {

    width          : 350px;
    justify-content: center;
    margin         : 10px;
  }
} */