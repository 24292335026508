.edit-route-page {
    width        : 50vw;
    border       : 4px solid #6A9598;
    border-radius: 20px;
    margin       : auto;
    padding      : 5px;
    align-content: center;
    justify-content: center;
}

.edit-route-text {
    text-align: center;
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
}

.edit-route-dropdown {
    width: 50%;
    margin: auto;
}

