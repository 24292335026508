.driver-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid var(--chaeban-secondary--border);
    width: fit-content;
    margin: auto;
    padding: 2rem;
    border-radius: 50px;
    margin-top: 50px;
    min-width: 200px;
}

.driver-group-tiles {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.group-title-button {
    width: auto;
    margin: auto;
    background-color: var(--chaeban-secondary--bg);
    border: 2px solid var(--chaeban-secondary--border);
    border-radius: 100px;
    color: var(--chaeban-secondary);
    margin: 10px;
    padding: 1rem;
    cursor: pointer;
}

.driver-text {
    text-align: center;
    color: var(--chaeban-secondary);
}

.driver-text span {
    font-weight: 600;

}

.logo-picklist img {
    width: 150px;
    height: 15vh;
   
}

@media only screen and (max-width: 500px) {

    .driver-homepage {
        width: 70vw;;
    }


}

