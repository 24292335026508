.start-end-time-page {
    display       : flex;
    flex-direction: column;
}

.datepicker-title {
    display        : flex;
    padding        : 15px 25px 15px 0px;
    justify-content: space-between;

}

.start-end-time-page .zone-submit-button {
    margin: auto;
}

.capacity-input {
    display        : flex;
    justify-content: space-between;
    align-items: center;

}

.capacity-input-field{
    padding-right: 20px;
}