.add-edit-zone-page {
    width        : 50vw;
    border       : 4px solid #6A9598;
    border-radius: 20px;
    margin       : auto;
    padding      : 5px;
    justify-content: center;
}

.add-edit-zone-page-text {
    text-align: center;
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
}

.add-edit-zone-page-selections {
    width: 30%;
    margin: auto;
    justify-content: space-between;
}