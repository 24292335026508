.all-zone-page {
    display       : flex;
    flex-direction: column;
}

.all-zone-page-text {

    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;

}