.add-zone-page {
    width        : 50vw;
    border       : 4px solid #6A9598;
    border-radius: 20px;
    margin       : auto;
    padding      : 5px;
    display: flex;
    flex-direction: column;
   
}


.add-zone-text {
    text-align: center;
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
}


.submit-button {
    margin: auto;
    justify-content: center;
}