.Product-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0%;
  flex-direction: column;
}

.details {
  flex-wrap: wrap;
}

/* Material UI override*/
.MuiAccordionSummary-root {
  flex-direction: row-reverse;
  border: 5px solid var(--chaeban-color-green--100) !important;
  border-radius: 1rem !important;
  margin: 5px !important;
  align-items: center;
}

.MuiAccordionSummary-content > p,
.MuiIconButton-label {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: var(--chaeban-color-green--100);
}

.MuiIconButton-root {
  padding: 0 !important;
  margin-right: 1rem !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiPaper-elevation1 {
  box-shadow: unset;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .MuiAccordionDetails-root.details {
    justify-content: center;
  }
  .button--primary {
    width: auto !important;
  }
}
