.flavor-picker__backdrop {
  background-color: rgba(50, 50, 50, 0.6);
  bottom: 0;
  left: 0;
  padding: 8px;
  position: fixed;
  right: 0;
  top: 0;
}

.flavor-picker__wrapper {
  background-color: #fff;
  border-radius: 16px;
  margin: 0;
  max-width: 1000px;
  overflow-y: auto;
  padding: 8px;
  position: relative;
  width: 95vw;
}

.flavor-picker__current {
  background-image: linear-gradient(0deg, transparent, #fff 16px, #fff);
  padding-bottom: 8px;
  padding-top: 8px;
  position: sticky;
  text-align: center;
  top: 0;
  transform: translateY(-8px);
  width: 100%;
  z-index: 1;
}

.flavor-picker__current .flavor-item {
  width: 340px;
}

.flavor-picker__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@supports (gap: 0) {
  .flavor-picker__list {
    gap: 8px;
  }
}

@supports not (gap: 0) {
  .flavor-picker__list {
    grid-gap: 8px;
  }
}
