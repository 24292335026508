.order-summary {
    margin: 16px;
}

.order-summary h1 {
    color        : var(--chaeban-primary);
    margin-bottom: 0;
    margin-top   : 5px;
    text-align   : center;
}

.flav-summary-container {
    display          : flex;
    flex-direction   : column;
    margin           : 15px;
    /* border        : 2px solid var(--chaeban-primary); */
    border-radius    : 20px;
    width            : 75vw;

}


.flavor-item_summary {
    align-items     : center;
    background-color: #fff;
    border          : 2px solid var(--chaeban-primary);
    border-radius   : 100px;
    color           : var(--chaeban-primary);
    display         : flex;
    padding         : 8px;
    width           : 30vw;
    margin          : 2px;

}

.flav-items-wrapper {
    display        : flex;
    justify-content: center;
    height         : 100%;
    flex-wrap      : wrap;

}

/* .flavor-item_summary:focus,
.flavor-item_summary:hover {
    filter: drop-shadow(2px 4px 6px var(--chaeban-primary));
} */

.flavor-item__img-wrapper_summary {
    background-color   : var(--chaeban-primary--bg);
    background-position: center;
    background-size    : cover;
    border             : none;
    border-radius      : 100px;
    height             : 18vw;
    margin             : -8px;
    margin-right       : 8px;
    max-height         : 80px;
    max-width          : 80px;
    overflow           : hidden;
    width              : 18vw;
}

.flavor-item__text_summary {
    font-weight   : 600;
    flex-grow     : 1;
    text-align    : initial;
    text-transform: uppercase;
    width         : 10%;
}

.flavor-item__name_summary {
    font-size: 1.2rem;
}


.del-pref {
    margin     : 10px;
    color      : var(--chaeban-primary);
    text-align : center;
    font-size  : 1.2rem;
    font-weight: bold;

}

@media only screen and (max-width: 920px) {
    .flavor-item__name_summary {
        font-size: 0.8rem;
    }

    .flavor-item__img-wrapper_summary {

        height      : 14vw;
        margin      : -8px;
        margin-right: 8px;
        max-height  : 60px;
        max-width   : 60px;
        width       : 14vw;
    }

    .del-pref {
        font-size: 1rem;

    }
}


@media only screen and (max-width: 770px) {


    .flavor-item_summary {
        width: 60%;
    }

}

@media only screen and (max-width: 600px) {


    .flavor-item_summary {
        width: 85%;
    }

}

@media only screen and (max-width: 300px) {


    .flavor-item_summary {
        min-width       : 200px;
    }

}

/* .loader-container {
    background-color: whitesmoke;
    width           : 100%;
    height          : 100%;
    position        : fixed;
} */

.loader {
    top                : 50%;
    left               : 50%;
    position           : fixed;
    transform          : translate(-50%, -50%);
    /* background-color: yellow;
    width              : 100%;
    height             : 100%; */
}

/* 
.confirmation-popup {
    align-items: center;
    text-align: center;
    border: 15px solid red;
} */