.zone-card-container {
    align-items    : center;
    justify-content: center;
}

.delivery-topic {
    color        : var(--chaeban-primary);
    margin-bottom: 0;
    margin-top   : 5px;
    text-align   : center;
}



.zone-cards {
    display            : flex;
    flex-wrap          : wrap;
    justify-content    : space-between;
    align-items        : center;
    margin             : auto;
    /* background-color: violet; */
}

.delivery-highligh {
    /* border       : 2px solid red; */
    border-radius      : 25px;
    padding            : 0.5rem;
    /* background-color:orange; */
    background         : #fdc7c7;
    /* background      : linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,199,199,1) 0%, rgba(252,176,69,1) 100%); */
}


.address-bar {
    border       : 1px solid var(--chaeban-primary);
    text-align   : center;
    width        : 30vw;
    margin       : auto;
    border-radius: 15px;
    color        : var(--chaeban-primary);
}

.delivery-zone-text {
    color: var(--chaeban-primary);
    text-align: center;
    margin: 20px;
    font-weight: bold;
}


.map-button {
    display       : flex;
    flex-direction: column;
    width         : 20vw;
    margin        : auto;
    align-items   : center;
    color         : 'var(--chaeban-primary)';


}



@media only screen and (max-width: 720px) {
    .zone-cards {
        justify-content: center;
    }

    .address-bar {
        border       : black;
        text-align   : center;
        width        : 350px;
        margin       : auto;
        border-radius: 15px;
        color        : var(--chaeban-primary);

    }

    .map-button {
        display       : flex;
        flex-direction: column;
        width         : 350px;
        margin        : auto;
        align-items   : center;
        color         : 'var(--chaeban-primary)';


    }

    .zone-dropdown {
        min-width: 300px;
    }
}




/* span {
    color: var(--chaeban-primary);
} */

.zone-dropdown {
    width              : 30vw;
    margin             : auto;
    padding-top        : 20px;
    /* background-color: violet; */
}

.zone-dropdown.picker-open {
    display: none;
}

.combine-orders {
    display        : flex;
    margin         : auto;
    align-items    : center;
    justify-content: center;
}