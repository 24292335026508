.Cart-wrapper.Cart--open {
  display: flex;
  flex-direction: column;
  border: 3px solid var(--chaeban-primary);
  border-radius: 10px;
  width: 500px;
  position: relative;
  right: 0;
  top: 0;
  height: 100%;
  top: 0;
  font-size: 0.8rem;
  margin-top: 4px;
}

.Cart-wrapper header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0 0.5rem;
  color: var(--chaeban-primary);
  font-weight: bold;
  text-transform: uppercase;
}

.Cart__line-items {
  padding-left: 0%;
  border-bottom: 3px solid var(--chaeban-primary);
}

.cart-item {
  background-color: red;
}

.Cart__footer {
  display: flex;
  flex-direction: column;
  color: var(--chaeban-primary);
  font-weight: bold;
  text-transform: uppercase;
}

.Cart-info.clearfix {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.Cart_checkout {
  background-color: var(--chaeban-primary);
  font-size: 1rem;
  border: 1px solid var(--chaeban-primary);
  border-radius: 10px;
  color: whitesmoke;
}

.Cart__close {
  background-color: var(--chaeban-primary);
  border-radius: 10px;
  border: 3px solid var(--chaeban-primary);
  color: whitesmoke;
  font-size: 1rem;
}

.cart-info {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  cursor: pointer;
}

.cart-icon-badge {
  background-color: var(--chaeban-primary);
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 50%;
  font-size: small;
  text-align: center;
  margin-left: 5px;
}

.MuiSvgIcon-root {
  width: 2rem !important;
  height: 2rem !important;
}
