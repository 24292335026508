.Line-item__img img {
    width : 60px;
    height: 60px;
}


.Line-item-wrapper {

    display: flex;
    width  : 95%;
    padding: 0.5rem;

    /* justify-content: space-between; */

}

.Line-item__content {
    margin-left: 15px;
    width      : 100%;

}

.Line-item-title__content-row {
    justify-content: flex-start;
    margin-bottom  : 10px;
}

.Line-item__content-row {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 5px;
    color          : var(--chaeban-primary)
}

.Line-item__title {
    padding-bottom : 0.5rem;
    justify-content: flex-start;
    color          : var(--chaeban-primary);
    font-weight    : bold;
    text-transform : uppercase;
}


.Line-item__quantity-container {
    justify-content: flex-start;
}

.Line-item__quantity {
    margin: 0px 0.5rem;
}

.Line-item__price {
    font-weight   : bold;
    align-items   : center;
    color         : var(--chaeban-primary);
    font-weight   : bold;
    text-transform: uppercase;
    margin-top    : 5px;

}

.Line-item__quantity-update,
.Line-item__remove {
    background-color: var(--chaeban-primary);
    border-radius   : 10px;
    border          : 3px solid var(--chaeban-primary);
    color           : whitesmoke;
    font-size       : 1rem;
}

@media only screen and (max-width: 721px) {



    .Line-item__price {

        padding-top: 0%;
    }


}


@media only screen and (max-width: 495px) {

    .Line-item__price {

        margin-top: 0%;
    }


    .Line-item__quantity-update,
    .Line-item__remove {
        padding         : 0%;
        /* border       : 0px solid rebeccapurple; */
        border-radius   : 0%;
        border          : none;
        background-color: transparent;
        color           : var(--chaeban-primary);

    }


}