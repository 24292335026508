.Product {
  width: 200px;
  height: 280px;
  border: 3px solid var(--chaeban-primary);
  border-radius: 10px;
  margin: 5px;
  padding-bottom: 0px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.Product img {
  height: 50%;
  width: 90%;
  object-fit: contain;
}

.Product__title {
  font-size: 0.7rem;
  color: var(--chaeban-primary);
  font-weight: bold;
  text-transform: uppercase;
  margin: 1px;
}

.Product__price {
  color: var(--chaeban-primary);
  font-weight: bold;
  font-size: 0.8rem;
  margin: 0.35rem;
}

.product-footer {
  margin: 0 10px;
}

.product-info {
  position: absolute;
  bottom: 0.35rem;
}

.Product__buy {
  background-color: var(--chaeban-primary);
  font-size: 0.7rem;
  border: 1px solid var(--chaeban-primary);
  border-radius: 10px;
  color: whitesmoke;
}

.Product__option {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.2rem;
  color: var(--chaeban-primary);
  font-weight: bold;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 0.35rem;
}

.add-to-cart-button {
  text-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.qty-input {
  width: 50%;
  border-color: var(--chaeban-primary);
  border-radius: 5px;
  height: 1remh;
  text-align: center;
  padding-left: 8px;
}

.info {
  margin-bottom: 5px !important;
}
