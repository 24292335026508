.picklist-table-page {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.pick-table {
    margin   : auto;
    width    : 30vw;
    min-width: 450px;

}

.pick-table-instructions {
    margin    : auto;
    width     : 70vw;
    min-height: 1000px;
}

table {
    border-spacing: 0px;
    table-layout  : fixed;
    margin-left   : auto;
    margin-right  : auto;
    width         : 300px;
}

td {
    border    : 2px solid var(--chaeban-secondary--border);
}

.product-col {
    width: 85%;
}

.qty-col {
    width: 15%;
    text-align: center;
    
}

.instructions-table {
    text-align: center;
}


@media only screen and (max-width: 1300px) {


    .pick-table {
        width: 50vw;
    }

}

@media only screen and (max-width: 800px) {


    .pick-table {
        width: 70vw;
    }

}

@media only screen and (max-width: 550px) {


    .pick-table {
        width: 90vw;
    }

    .pick-table-instructions {
        width: 90vw;
    }


}