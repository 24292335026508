html * {
  --chaeban-primary: #e1787b;
  --chaeban-primary--bg: #f9e4e5;
  --chaeban-secondary: #4e8e95;
  --chaeban-secondary--bg: #f1f6f7;
  --chaeban-secondary--border: #2a9097;
  --chaeban-color-green--100: #4b8e94;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout--centered {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.button--primary {
  background-color: var(--chaeban-primary);
  border: 5px solid var(--chaeban-primary);
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 10px;
  font-size: 16px;
}
