.order-number-select {
  color: var(--chaeban-primary);
  margin: 16px;
  text-align: center;
}

.order-number-select__title {
  margin-bottom: 0;
}

.order-number-select__text {
  font-weight: 600
}

.order-number-select__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  max-width: 500px;
}

@supports (gap: 0) {
  .order-number-select__list {
    gap: 8px;
  }
}

@supports not (gap: 0) {
  .order-number-select__list {
    grid-gap: 8px;
  }
}

.close-page-button {
  background-color: green;
  font: bold;
  color: whitesmoke;
  border: 1px solid green;
  padding: 0.5rem;
  width: 20vw;
  border-radius: 30px;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  text-transform: uppercase;
}
