.button--primary {
  text-decoration: none !important;
  width: 150px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  color: var(--chaeban-primary);
  font-weight: bold;
  padding: 1rem;
}

.container,
.logo,
.header,
.details,
.footer,
.buttons {
  display: flex;
}

.logo {
  justify-content: center;
}

.header {
  justify-content: center;
}

.details {
  flex-direction: row;
  padding: 0.5rem;
  flex-basis: 100%;
}

.product-details {
  flex: 2;
}

.Cart-wrapper {
  height: auto !important;
}

.cart-close {
  display: none;
}

.buttons {
  display: flex;
  flex: 1 1 auto;
}

.finalize-button {
  margin-left: auto;
}

.MuiIconButton-label {
  padding: 0;
}

.MuiTouchRipple-root {
  padding: 0 !important;
  text-align: center !important;
}

.MuiAccordionDetails-root {
  flex-direction: row !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) and (min-width: 260px) {
  .container {
    padding: 0.25rem;
  }
  .header {
    font-size: x-small;
    padding: 1rem;
  }
  .header h2 {
    margin-bottom: 0 !important;
  }
  .title {
    margin-bottom: 0 !important;
  }
  .details {
    flex-direction: column-reverse;
  }
  .cart,
  .Cart-wrapper {
    width: 100% !important;
  }

  .cart-info {
    align-items: flex-end;
    margin: 0 !important;
  }

  .details {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1180px) {
  .details {
    flex-direction: column-reverse;
  }
  .cart,
  .Cart-wrapper {
    width: 100% !important;
  }

  .cart-info {
    align-items: flex-end;
    margin: 0 !important;
  }

  .details {
    margin-top: 0 !important;
  }
}
