.zone-card {
  display          : flex;
  flex-direction   : column;
  padding          : 5px;
  align-items      : center;
  font-size        : medium;
  font-weight      : bold;
  position         : relative;
  border           : 1px solid var(--chaeban-primary);
  margin           : 20px;
  border-radius    : 15px;
  color            : var(--chaeban-primary);
  /* width         : fit-content */
}

img {
  border-radius: 20px;
}

@media only screen and (max-width: 720px) {
  /* body {
    background-color: rgb(83, 31, 25);

  } */

  .zone-name-text {
    font-size: 0.75rem;
  }

  .zone-card {
    display       : flex;
    flex-direction: column;
    width         : 10vw;
    height        : 90px;
    margin        : 5px;
    margin-top    : 10px;
    align-items   : center;
    padding-bottom: 20px;

  }

  img {
    border-radius: 20px;
    width        : 90%;
    height       : 90%;
  }

  span {
    font-size  : small;
    padding-top: 10px;
  }

  .text {
    font-size: 5px;
    color    : slateblue;

  }
}

.zone-card.selected {
  background-color: var(--chaeban-primary);
  color           : white;
}

/* .zone-card.span {
  color: var(--chaeban-primary);
} */


.overlay {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  left            : 0;
  right           : 0;
  height          : 100%;
  width           : 100%;
  opacity         : 0;
  transition      : .5s ease;
  background-color: var(--chaeban-primary);
  border-radius   : 15px;
}

.zone-card:hover .overlay {
  opacity: 1;
}


.text {
  color            : white;
  font-size        : 20px;
  position         : absolute;
  top              : 50%;
  left             : 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform    : translate(-50%, -50%);
  transform        : translate(-50%, -50%);
  text-align       : center;
}