.pop-up-card-confirmation {
    position          : absolute;
    width             : 30vw;
    /* height         : 40vh; */
    text-align        : center;
    z-index           : 1;
    align-items       : center;
    border-radius     : 30px;
    display           : flex;
    flex-direction    : column;
    left              : 50%;
    top               : 50%;
    transform         : translate(-50%, -50%);
    /* to keep the pop up centerd */
    padding           : 0;
    justify-content   : center;
    padding           : 0.5rem;



}

.pop-up-card-confirmation.success {
    background-color: whitesmoke;
    border          : 4px solid green;
    min-width: 100px;


}

.pop-up-card-confirmation.fail {
    background-color: whitesmoke;
    border          : 4px solid tomato;
    color           : tomato;
    min-width: 160px;

}

.pop-up-card-confirmation h1 {
    margin-top: 0%;

}

.pop-up-card-confirmation.success h1 {
    color: green;

}



.close-button {
    padding-top: 20px;
}

.pop-up-card span {
    font-style: italic;
}


@media only screen and (max-width: 660px) {


    .pop-up-card-confirmation.success h1 {
        font-size: 1rem;
    }

    .pop-up-card-confirmation.fail h1 {
        font-size: 1rem;
    }

    .pop-up-card-confirmation.fail span {
        font-size: 0.8rem;
    }



}