.add-route-page {
    width        : 50vw;
    border       : 4px solid #6A9598;
    border-radius: 20px;
    margin       : auto;
    padding: 5px;
}

.add-route-text {
    text-align: center;
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
}

.add-route-inputfield {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}