.flavor-change-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  max-width: 1200px;
  padding-bottom: 0px;
  width: 100%;
  margin: auto;
}


.flavor-change-form__btn {
  background-color: var(--chaeban-primary);
  border: none;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  margin: auto;
  max-width: 400px;
  min-height: 64px;
  padding: 8px 16px;
  transition: all 0.1s ease-out;
  width: 100%;
}

.flavor-change-form__btn:focus,
.flavor-change-form__btn:hover {
  opacity: 0.7;
}

.flavor-change-form__btn :first-child {
  font-size: 1.3rem;
  font-weight: 600;
}

@media (max-width: 360px) {
  .flavor-change-form {
    grid-template-columns: 1fr;
  }
}

@supports (gap: 0) {
  .flavor-change-form {
    gap: 8px;
  }
}

@supports not (gap: 0) {
  .flavor-change-form {
    grid-gap: 8px;
  }
}
