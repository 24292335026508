.lockorder-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid var(--chaeban-secondary--border);
    width: fit-content;
    margin: auto;
    padding: 2rem;
    border-radius: 50px;
    margin-top: 50px;
    min-width: 200px;
}

.lockorder-homepage button {
    width: auto;
    margin: auto;
    background-color: var(--chaeban-secondary--bg);
    border: 2px solid var(--chaeban-secondary--border);
    border-radius: 100px;
    color: var(--chaeban-secondary);
    margin: 10px;
    padding: 1rem;
    cursor: pointer;
}

.lockorder-text {
    text-align: center;
    color: var(--chaeban-secondary);
}