.flavor-item {
  align-items: center;
  background-color: #fff;
  border: 2px solid var(--chaeban-primary);
  border-radius: 100px;
  color: var(--chaeban-primary);
  cursor: pointer;
  display: flex;
  padding: 8px;
  transition: all 0.1s ease-out;
  width: 100%;
}

.flavor-item:focus,
.flavor-item:hover {
  filter: drop-shadow(2px 4px 6px var(--chaeban-primary));
}

.flavor-item__img-wrapper {
  background-color: var(--chaeban-primary--bg);
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 100px;
  height: 18vw;
  margin: -8px;
  margin-right: 8px;
  max-height: 80px;
  max-width: 80px;
  overflow: hidden;
  width: 18vw;
}

.flavor-item__text {
  font-weight: 600;
  flex-grow: 1;
  text-align: initial;
  text-transform: uppercase;
  width: 10%;
}

.flavor-item__name {
  font-size: 1rem;
}

.flavor-item__info {
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-top: 4px;
}

.flavor-item__quantity {
  align-items: center;
  background-color: var(--chaeban-primary);
  border-radius: 100px;
  color: #fff;
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  height: 2em;
  justify-content: center;
  overflow: visible;
  white-space: nowrap;
  width: 2em;
}

@media (max-width: 360px) {
  .flavor-item__name {
    font-size: 0.9rem;
  }
}