.zone-select-page {

    width          : 50vw;
    border         : 4px solid #6A9598;
    border-radius  : 20px;
    margin         : auto;
    padding        : 10px;
    align-content  : center;
    justify-content: center;

}

.zone-select-text {
    padding-bottom: 15px;
    font-weight   : bold;
    font-size     : medium;
    text-align: center;
}

.zone-select-dropdown {
    width: 50%;
    margin: auto;
}