.pop-up-card {
    position        : absolute;
    width           : 30vw;
    height          : 20vh;
    background-color: var(--chaeban-secondary--bg);
    text-align      : center;
    top             : 35vh;
    z-index         : 1;
    border          : 5px salmon solid;
    align-items     : center;
    border-radius   : 5px;
    display: flex;
    flex-direction: column;
}

.close-button {
    padding-top: 20px;
}

.pop-up-card span {
    font-style: italic;
}