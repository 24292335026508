.order-item {
  background-color: var(--chaeban-secondary--bg);
  border: 2px solid var(--chaeban-secondary--border);
  border-radius: 100px;
  color: var(--chaeban-secondary);
  font-weight: 600;
  justify-content: center;
  padding: 8px 24px;
  text-decoration: none;
  transition: all 0.1s ease-out;
}

.order-item:focus,
.order-item:hover {
  filter: drop-shadow(2px 4px 6px var(--chaeban-secondary--border));
}

.order-item > * + * {
  margin-top: 4px;
}

.order-item :first-child {
  text-transform: uppercase;
}