.actions {
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: var(--chaeban-primary);
  font-weight: bold;
  padding: 1rem;
  text-align: center;
}

.message {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: var(--chaeban-color-green--100);
  font-weight: bold;
}

.MuiDialog-paper {
  border: 5px solid var(--chaeban-color-green--100);
  border-radius: 1rem !important;
  padding: 5px;
}
